@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  /* background-color: #f2c8ad; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
.container h2{
  word-spacing: 10pt;
  background-color: #40096a;
  padding: 1% 5% 1% 5%;
  border-radius: 100px;
  font-family: 'Raleway', sans-serif;
  color: #fff;
  text-shadow: 5px 5px 10px #ddd;
}
.container textarea,button{
  width: 50%;
  font-family: "Montserrat", sans-serif;
  padding: 1%;
  outline: none;
  border: none;
  border-radius: 10px;
  margin-top: 8%;
  color: #40096a;
  text-shadow: 5px 5px 10px #40096a;
  text-transform: capitalize;
  box-shadow: 5px 5px 15px #000;
}
.container button{
  width: 30%;
  border-radius: 100px;
  margin-top: 5%;
  cursor: pointer;
  -webkit-animation: button-shake 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: button-shake 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
@-webkit-keyframes button-shake {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
            transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
            transform: translateY(-6.4px);
  }
}
@keyframes button-shake {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
            transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
            transform: translateY(-6.4px);
  }
}
.container button:hover{
  transition: .4s;
  width: 20%;
  background-color: #40096a;
  color: #fff;
}

.navBar {
  display: flex;
  flex-direction: row;
  padding: 1%;
  background-color: #fff;
  z-index: 1000;
}
#logo {
  width: 20%;
  padding: 1%;
}
.videoTag {
  width: 100%;
  position: fixed;
  z-index: -10;
  -webkit-animation: bg-pan-tl 8s infinite both;
  animation: bg-pan-tl 8s infinite both;
}
#icon{
  width: 5%;
  margin-left: 50%;
  -webkit-animation-name: float;
          animation-name: float;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.focus-in-contract-bck {
	-webkit-animation: focus-in-contract-bck 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-contract-bck 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}


/* RESPONSIVE CODE */
@media screen and (max-width: 950px) {
 #logo{
   width: 25%;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
 }
 #icon{
   width: 6%;
   height: -webkit-fit-content;
   height: -moz-fit-content;
   height: fit-content;
 }
 h4{
   font-size: 9pt;
 }
}
@media screen and (max-width: 850px) {
  #logo{
    width: 23%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #icon{
    width: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

  }
 }
 @media screen and (max-width: 750px) {
  #icon{
    width: 5.5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  h4{
    font-size: 7.5pt;
  }
  h2{
    font-size: 10pt;
    margin-bottom: -5%;
  }
  .container textarea,button{
    width: 40%;
    padding: 2%;
    font-size: 10pt;
  }
  .container button{
    padding: .5% 2% .5% 2%;
  }
 }
 @media screen and (max-width: 768px){
  h2{
    font-size: 8pt;
    margin-bottom: -5%;
    margin-top: 5%;
  }
  .container textarea,button{
    width: 40%;
    padding: 2%;
    font-size: 8pt;
  }
  .container button{
    padding: .5% 2% .5% 2%;
  }
 }
 @media screen and (max-width: 600px) {
  #logo{
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #icon{
    width: 5%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .navBar h4{
    font-size: 7pt;
  }
  h2{
    font-size: 8pt;
    margin-bottom: -5%;
  }
  .container textarea,button{
    width: 40%;
    padding: 2%;
    font-size: 7pt;
  }
  .container button{
    padding: .5% 2% .5% 2%;
  }
 }
 @media screen and (max-width: 500px) {
  #logo{
    width: 25%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #icon{
    width: 7%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .navBar h4{
    font-size: 5pt;
  }
  h2{
    font-size: 6pt;
    margin-bottom: -4%;
  }
  .container textarea,button{
    width: 40%;
    padding: 2%;
    font-size: 7pt;
  }
  .container button{
    width: 20%;
    padding: .5% 3% .5% 3%;
  }
 }

 @media screen and (max-width: 400px) {
  #logo{
    width: 30%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #icon{
    width: 8%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .navBar h4{
    font-size: 3pt;
  }
  h2{
    font-size: 4.5pt;
    margin-bottom: -3.5%;
  }
  .container textarea,button{
    width: 50%;
    padding: 2%;
    font-size: 6pt;
  }
  .container button{
    width: 20%;
    padding: .5% 3% .5% 3%;
  }
 }


 @media screen and (max-width: 414px) {

  .navBar h4{
    font-size: 3pt;
  }
  h2{
    font-size: 4.5pt;
    margin-bottom: -3.5%;
  }
  .container textarea,button{
    width: 50%;
    padding: 2%;
    font-size: 6pt;
  }
  .container button{
    width: 20%;
    padding: .5% 3% .5% 3%;
  }
 }
 /* @media screen and (max-width: 375px) {
  .container textarea,button{
    width: 50%;
    padding: 2%;
    font-size: 6pt;
  }
  .container button{
    width: 20%;
    padding: .5% 3% .5% 3%;
    margin-bottom: 6%;
  }
 } */
 @media screen and (max-width: 360px){
   .container button{
     margin-top: 3%;
   }
   .container textarea, button{
     font-size: 5pt;
   }
   #icon{
    width: 6%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
   }
 }
